<template>
    <div>
      <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
      <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
      <supplier-show-table :paginationData="paginationData" @pageChange="pageChange" @buttonClick="buttonClick" :tableData="tableData"></supplier-show-table>
    </div>
</template>

<script>
import { request } from '@/assets/js/http.js'
import ListSearch from '@/components/actionList/ListSearch.vue'
import SupplierShowTable from './SupplierShowTable.vue'
import { SupplierListSearchList, SupplierListSearchData } from './js/SupplierConfig.js'
import ListMenu from '@/components/actionList/ListMenu.vue'
export default {
  components: { ListSearch, SupplierShowTable, ListMenu },
  name: 'SupplierList',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    request('/api/supplier/basicData/list?approvalStatus=3', 'get', { pageNum: 1, pageSize: pageSize }).then((Response) => {
      if (Response.code === '200') {
        this.tableData = Response.data.records
        this.paginationData = { pageSize: pageSize, total: Response.data.total }
      }
    })
    return {
      childData: { searchList: SupplierListSearchList, searchData: SupplierListSearchData },
      menudata: this.$store.state.ViewMenuData.menuData,
      tableData: [],
      paginationData: {},
      searchdata: {},
      pageSize: pageSize
    }
  },
  methods: {
    buttonClick (data) {
      if (data.openView === 'openView') {
        this.$router.push({ params: { viewName: data.alias }, query: data.query })
        this.$emit('OpenView', { openView: data.alias })
      }
    },
    pageChange (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      request('/api/supplier/basicData/list?approvalStatus=3', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          // this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    search (data) {
      data.pageNum = 1
      data.pageSize = this.pageSize
      this.searchdata = data
      request('/api/supplier/basicData/list?approvalStatus=3', 'get', data).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  }
}
</script>

<style scoped>

</style>
