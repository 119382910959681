<template>
<div class="tableList">
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%">
    <el-table-column prop="erpCode" label="编码"> </el-table-column>
    <el-table-column prop="name" label="供应商名称" :show-overflow-tooltip="true" width="300%"> </el-table-column>
    <el-table-column prop="supplyMaterialType" label="供应物料" ></el-table-column>
    <el-table-column prop="businessScope" label="经营范围" >
      <template #default="scope">
        {{setBusinessScope(scope.row.businessScopeList)}}
      </template>
    </el-table-column>
    <el-table-column prop="status" label="状态" >
      <template #default="scope">
        {{scope.row.status=="1"?"淘汰":scope.row.status=="2"?"冻结":scope.row.status=="3"?"启用":""}}
      </template>
    </el-table-column>
    <el-table-column prop="type" label="级别" >
      <template #default="scope">
        {{scope.row.type=="1"?"一次":scope.row.type=="2"?"一般":scope.row.type=="3"?"合格":""}}
      </template>
    </el-table-column>
    <el-table-column prop="buyerName" label="采购员" > </el-table-column>
    <el-table-column label="操作" width="300">
      <template #default="scope">
        <el-button
          size="mini"
          type="text"
          @click="handleShow(scope.$index, scope.row)">查看</el-button>
        <el-button
          size="mini"
          type="text"
          @click="handleEdit(scope.$index, scope.row)">修改</el-button>
        <el-button
          v-if="(scope.row.type < 3 && verbuyer(scope.row.buyerCode))"
          size="mini"
          type="text"
          @click="handleUp(scope.$index, scope.row)">升级</el-button>
        <el-button
          v-if="verbuyer(scope.row.buyerCode)"
          size="mini"
          type="text"
          @click="handleDown(scope.$index, scope.row)">降级或淘汰</el-button>
          <el-button
          v-if="true||verbuyer(scope.row.buyerCode)"
          size="mini"
          type="text"
          @click="handlePayClause(scope.$index, scope.row)">修改付款条件</el-button>
      </template>
    </el-table-column>
  </el-table>
  </div>
    <div class="tablepage" v-if="tableData&&tableData.length>0">
      <el-pagination @current-change="currentChange"
          :page-size="paginationData.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="paginationData.total">
        </el-pagination>
    </div>
</div>
</template>
<script>
export default {
  name: 'SupplierShowTable',
  props: ['tableData', 'paginationData'],
  methods: {
    verbuyer (buyer) {
      const buyers = buyer.split(',')
      for (const buyercode of buyers) {
        if (this.$store.state.loginInfo.username === buyercode) {
          return true
        }
      }
      return false
    },
    setBusinessScope: (code) => {
      let rd = ''
      for (const d of code) {
        if (d === 'KK') {
          rd = rd + '可控;'
        }
        if (d === 'SG') {
          rd = rd + '曙光;'
        }
        if (d === 'NC') {
          rd = rd + '宁畅;'
        }
        if (d === 'TL') {
          rd = rd + '腾龙;'
        }
        if (d === 'SC') {
          rd = rd + '数创;'
        }
      }
      return rd.split(';').filter((s) => { return s && s.trim() }).join(';')
    },
    handleShow (index, data) {
      this.$emit('buttonClick', { alias: 'SupplierRegistration', openView: 'openView', query: { id: data.id, type: 'show' } })
    },
    handleEdit (index, data) {
      this.$emit('buttonClick', { alias: 'SupplierRegistration', openView: 'openView', query: { id: data.id, type: 'edit' } })
    },
    handleUp (index, data) {
      this.$emit('buttonClick', { alias: 'SupplierRegistration', openView: 'openView', query: { id: data.id, type: 'show', controlLevel: 'up', level: data.type } })
    },
    handleDown (index, data) {
      this.$emit('buttonClick', { alias: 'SupplierRegistration', openView: 'openView', query: { id: data.id, type: 'show', controlLevel: 'down', level: data.type } })
    },
    handlePayClause (index, data) {
      this.$emit('buttonClick', { alias: 'SupplierRegistration', openView: 'openView', query: { id: data.id, type: 'show', action: 'payClauseChange' } })
    },
    currentChange (data) {
      this.$emit('pageChange', data)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
</style>
